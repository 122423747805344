<script>
export default {
  mounted() {
    this.sendCode();
  },

  methods: {
    sendCode() {
      const payload = {
        confirmation_code: this.$route.params.code,
      }

      this.$store.dispatch('auth/confirmSignupCode', payload);

      this.$store.dispatch('welcomeModal/resetPasswordModal', true);
    }
  }
}
</script>

<style scoped>

</style>
